import router from '@/router';
// eslint-disable-next-line import/extensions
import { AuthAPI } from '../api/REST.js';
import {
  SEND_START,
  SEND_SUCCESS,
  SEND_ERROR,
  PHONE_LENGTH_CHECK,
  OTP_LENGTH_CHECK,
  TO_FIRST_STAGE,
  PASSWORD_LENGTH_CHECK,
  SET_CHECK_PHONE,
  SET_ERRORS_GLOBAL,
  CLEAR_ERRORS,
  CHANGE_STATE,
  OTP_CHECK_IPN_REQUEST,
  SET_ITN,
  CLOSE_POP_UP_TOOGLE,
} from './types';

export default {
  namespaced: true,
  state: {
    phoneNumberLengthError: false,
    otpLengthError: false,
    passwordLengthError: false,
    passwordError: false,
    phoneNumberError: false,
    otpError: false,
    loading: false,
    error: false,
    errorsGlobal: null,
    isNew: null,
    isStaff: null,
    stage: 1,
    urlType: 'staff',
    itnSuccess: false,
    toCall: false,
    isBanned: false,
    isValidPhone: false,
  },
  actions: {
    clearErrors({ commit }) {
      commit(CLEAR_ERRORS);
    },
    toFirstStageStore({ commit }) {
      commit(TO_FIRST_STAGE);
    },
    checkPhoneStore({ commit }, { phoneNumber }) {
      commit(PHONE_LENGTH_CHECK, { phoneNumber });
    },
    checkOTPStore({ commit }, { otp }) {
      commit(OTP_LENGTH_CHECK, { otp });
    },
    closeAuthPopUp({ commit }) {
      commit(CLOSE_POP_UP_TOOGLE);
    },
    checkPasswordStore({ commit }, { passwordForm }) {
      commit(PASSWORD_LENGTH_CHECK, { passwordForm });
    },
    checkNewClientItn({ commit }, payload) {
      commit(SEND_START);
      AuthAPI.checkUserByItn(payload)
        .then((data) => {
          commit(SET_ITN, data.payload);
          if (data.success === true) {
            commit(SEND_SUCCESS);
            commit(SET_ITN, data.payload);
          } else if (data.payload.to_call === true) {
            commit(SEND_SUCCESS);
          }
        })
        .catch(() => {
          console.log('error');
        });
    },
    sendPhoneStore({ commit }, payload) {
      commit(SEND_START);
      AuthAPI.checkPhoneAndSendOtp(payload)
        .then((data) => {
          if (data.success === true) {
            commit(SEND_SUCCESS);
            commit(SET_CHECK_PHONE, data.payload);
          } else {
            commit(SEND_ERROR);
            commit(SET_ERRORS_GLOBAL, data.errors);
          }
        }).catch(() => {
          commit(SEND_ERROR);
          commit(SET_ERRORS_GLOBAL, true);
        });
    },
    // checkNewClient({ commit }, payload) {
    //   commit(SEND_START);
    //   AuthAPI.getRedirectUrl(payload)
    //     .then((data) => {
    //       if (data.success === true) {
    //         commit(OTP_CHECK_IPN_REQUEST);
    //         commit(SEND_SUCCESS, false);
    //       } else {
    //         commit(SEND_ERROR);
    //         commit(SET_ERRORS_GLOBAL, data.errors);
    //       }
    //     })
    //     .catch(() => {
    //       commit(SEND_ERROR);
    //       commit(SET_ERRORS_GLOBAL, true);
    //     });
    // },
    getRedirectUrl({ commit, state }, payload) {
      commit(SEND_START);
      // const hasProductJWT = router.currentRoute.query && router.currentRoute.query.product;
      AuthAPI.getRedirectUrl(payload)
        .then((data) => {
          if (data.success === true && state.isNew !== true) {
            if (window.location.search.includes('product')) {
              let redirectUrl = data.payload.url;
              const hasProductJWT = window.location.search && window.location.search.includes('product');
              if (hasProductJWT) {
                const productJWT = router.currentRoute.query?.product;

                redirectUrl = `${data.payload.url}&product=${productJWT}`;
              }
              window.location.href = redirectUrl;
              commit(SEND_SUCCESS, true);
            } else if (window.location.search.includes('inv')) {
              let redirectUrl = data.payload.url;
              const hasProductJWT = window.location.search && window.location.search.includes('inv');
              if (hasProductJWT) {
                const productJWT = router.currentRoute.query?.inv;

                redirectUrl = `${data.payload.url}&inv=${productJWT}`;
              }
              window.location.href = redirectUrl;
              commit(SEND_SUCCESS, true);
            } else {
              let redirectUrl = data.payload.url;
              const hasProductJWT = window.location.search && window.location.search.includes('download');
              if (hasProductJWT) {
                const productJWT = router.currentRoute.query?.download;

                redirectUrl = `${data.payload.url}&download=${productJWT}`;
              }
              window.location.href = redirectUrl;
              commit(SEND_SUCCESS, true);
            }
          } else if (state.isNew === true && data.success === true) {
            commit(OTP_CHECK_IPN_REQUEST);
            commit(SEND_SUCCESS);
          } else {
            commit(SEND_ERROR);
            console.log(data.errors);
            commit(SET_ERRORS_GLOBAL, data.errors);
          }
        })
        .catch(() => {
          commit(SEND_ERROR);
          commit(SET_ERRORS_GLOBAL, true);
        });
    },
  },
  mutations: {
    [CHANGE_STATE](state, { key = '', value = '' }) {
      state[key] = value;
    },
    [CLEAR_ERRORS](state) {
      state.passwordError = false;
      state.phoneNumberError = false;
      state.otpError = false;
      state.errorsGlobal = null;
    },
    [TO_FIRST_STAGE](state) {
      state.phoneNumberLengthError = false;
      state.otpLengthError = false;
      state.passwordLengthError = false;
      state.phoneNumberError = false;
      state.otpError = false;
      state.passwordError = false;
      state.isNew = null;
      state.isStaff = null;
      state.stage = 1;
      state.loading = false;
      state.error = false;
      state.errorsGlobal = null;
    },
    [PASSWORD_LENGTH_CHECK](state, { passwordForm }) {
      if (passwordForm.password.length === 0) {
        state.errorsGlobal = [{
          message: 'Будь ласка, введіть пароль!',
          code: 'passwordLengthError',
        }];
      }
      state.passwordError = passwordForm.password.length === 0;
      state.passwordLengthError = passwordForm.password.length === 0;
    },
    [PHONE_LENGTH_CHECK](state, { phoneNumber }) {
      if (phoneNumber.length !== 19) {
        state.errorsGlobal = [{
          message: 'Будь ласка, введіть повний номер телефону!',
          code: 'phoneNumberLengthError',
        }];
      }
      state.phoneNumberError = phoneNumber.length !== 19;
      state.phoneNumberLengthError = phoneNumber.length !== 19;
    },
    [OTP_LENGTH_CHECK](state, { otp }) {
      if (!otp.valid && !state.isBanned) {
        state.errorsGlobal = [{
          message: 'Будь ласка, введіть повний код з смс!',
          code: 'otpLengthError',
        }];
      }
      state.otpError = !otp.valid;
      state.otpLengthError = !otp.valid;
    },
    [SEND_START](state) {
      state.loading = true;
      state.error = false;
      state.errorsGlobal = null;
      state.phoneNumberError = false;
      state.otpError = false;
      // state.itnError = false;
      state.passwordError = false;
    },
    [SEND_SUCCESS](state, loading = false) {
      state.loading = loading;
      state.error = false;
    },
    [SEND_ERROR](state, loading = false) {
      state.loading = loading;
      state.error = true;
    },
    [SET_CHECK_PHONE](state, payload) {
      state.isBanned = payload.is_banned;
      state.isNew = payload.is_new;
      state.isStaff = payload.is_staff;
      state.stage = 2;
    },
    [SET_ITN](state, checkUserByItn) {
      state.itnSuccess = checkUserByItn.success;
      state.toCall = checkUserByItn.to_call;
      state.isValidPhone = checkUserByItn.is_valid_phone;
      state.error = checkUserByItn.errors;
    },
    [CLOSE_POP_UP_TOOGLE](state) {
      state.toCall = false;
      state.itnSuccess = false;
    },
    [OTP_CHECK_IPN_REQUEST](state) {
      state.stage = 3;
    },
    [SET_ERRORS_GLOBAL](state, errors) {
      if (errors === true) {
        state.errorsGlobal = [{
          message: 'Щось пішло не так',
          code: 'server',
        }];
      } else {
        state.errorsGlobal = errors;
        // Object.values(state.errorsGlobal).forEach((error) => {
        switch (state.errorsGlobal) {
          case 'tooManyWrongAttempts':
            state.otpError = true;
            state.stage = 1;
            break;
          case 'noPassword':
            state.passwordError = true;
            break;
          /* case 'noItn':
            state.itnError = true;
            break; */
          case 'invalidOtpCode':
            state.otpError = true;
            break;
          /* case 'itnIsInvalid':
            state.itnError = true;
            break; */
          case 'invalidStaffPassword':
            state.passwordError = true;
            break;
          default:
            break;
        }
        // });
      }
    },
  },
  getters: {
    itn(state) {
      return state.itn;
    },
    toCall(state) {
      return state.toCall;
    },
  },
};
