export const PHONE_LENGTH_CHECK = 'PHONE_LENGTH_CHECK';
export const OTP_LENGTH_CHECK = 'OTP_LENGTH_CHECK';
// export const ITN_LENGTH_CHECK = 'ITN_LENGTH_CHECK';
export const PASSWORD_LENGTH_CHECK = 'PASSWORD_LENGTH_CHECK';
export const OTP_CHECK_IPN_REQUEST = 'OTP_CHECK_IPN_REQUEST';
export const SET_ITN = 'SET_ITN';

export const TO_FIRST_STAGE = 'TO_FIRST_STAGE';

export const SEND_START = 'SEND_START';
export const SEND_SUCCESS = 'SEND_SUCCESS';
export const SEND_ERROR = 'SEND_ERROR';

export const SET_CHECK_PHONE = 'SET_CHECK_PHONE';
export const SET_ERRORS_GLOBAL = 'SET_ERRORS_GLOBAL';
export const CLEAR_ERRORS = 'CLEAR_ERRORS';
export const CLOSE_POP_UP_TOOGLE = 'CLOSE_POP_UP_TOOGLE';

export const CHANGE_STATE = 'CHANGE_STATE';
