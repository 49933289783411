import { session } from './session_REST';

const BASIC_AUTH_USERNAME = process.env.VUE_APP_BASIC_AUTH_USERNAME;
const BASIC_AUTH_PASSWORD = process.env.VUE_APP_BASIC_AUTH_PASSWORD;
export const BASE_URL = process.env.VUE_APP_BACKEND_URL;
// export const BASE_URL = 'http://localhost:8000/';
const auth = {
  auth: {
    username: BASIC_AUTH_USERNAME,
    password: BASIC_AUTH_PASSWORD,
  },
};

// eslint-disable-next-line import/prefer-default-export
export const AuthAPI = {
  checkPhoneAndSendOtp(payload) {
    return session.post(`${BASE_URL}api/v1/check-phone`, { phone: payload.phone }, auth)
      .then((response) => response.data)
      .catch((err) => {
        console.log(err);
      });
  },
  getRedirectUrl(payload) {
    return session.post(`${BASE_URL}api/v1/get-url`, {
      phone: payload.phone,
      code: payload.code,
      password: payload.password,
      url_type: payload.urlType,
    }, auth)
      .then((response) => response.data)
      .catch((err) => {
        console.log(err);
      });
  },
  checkUserByItn(payload) {
    return session.post(`${BASE_URL}api/v1/check-itn`, {
      itn: payload.itn,
      phone: payload.phone,
    }, auth).then((response) => response.data)
      .catch((err) => {
        console.log(err);
      });
  },
  bankIdTest() {
    return session.get(`${BASE_URL}api/v1/bank-id-auth`, auth)
      .then((response) => response.data)
      .catch((err) => {
        console.log(err);
      });
  },
};
