export default {
  methods: {
    // eslint-disable-next-line consistent-return
    getTitle(vm) {
      const { title } = vm.$options;
      if (title) {
        return typeof title === 'function' ? title.call(vm) : title;
      }
    },
  },
  created() {
    const title = this.getTitle(this);
    if (title) {
      document.title = title;
    }
  },
};
