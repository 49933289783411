import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'Auth',
    component: () => import(
      /* webpackChunkName: "auth" */
      /* webpackMode: "lazy" */
      /* webpackExports: ["default", "named"] */
      '@/views/Auth'
    ),
  },
  /* {
    path: '/id-gov-ua',
    name: 'IdGovUaRedirect',
    component: () => import(
      /!* webpackChunkName: "idGovUa" *!/
      /!* webpackMode: "lazy" *!/
      /!* webpackExports: ["default", "named"] *!/
      '@/views/IdGovUaRedirect'
    ),
    props: (route) => ({
      code: route.query.code,
    }),
  }, */
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

export default router;
